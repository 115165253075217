import { Component } from '@angular/core';
import { ActivatedRoute, ActivationStart, Router } from '@angular/router';
import { ApiService } from 'lexi-api';

@Component({
  selector: 'lib-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css']
})
export class ViewComponent {

  public groups:any = {};
  public existing_path = "";
  public uuid : string = "";
  public subview:boolean = false

  constructor(private router: Router, private activedRoute: ActivatedRoute, private api: ApiService) { 

    this.router.events.subscribe(data => {
      if (data instanceof ActivationStart && data.snapshot.data) {
        let dt:any = data;
        if(dt.subview) {
          this.subview = dt.subview;
        }
      }
    });
    
    this.activedRoute.params.subscribe(async (v:any) => {
      console.log(v)
      if (!v.identifier || !v.uuid) {
        this.router.navigate(['/'])
        return;
      }

      this.existing_path = v.identifier
      this.uuid = v.uuid;
      this.groups = await this.api.post("/group/structure", {group_identifier: v.identifier, uuid: v.uuid})

      if(!this.activedRoute.firstChild) {
        var url = '/detail' + '/' + this.existing_path + '/' + this.uuid + '/' + this.groups.menus[0].type + '/' + this.groups.menus[0].path + '/' + this.uuid
        this.router.navigate([url])         
      }

    })
    
  }

  getCurrentRoutes() {
    return this.router.url;
  }

  getLink(menu:any) {
    return ['/detail', this.existing_path, this.uuid, menu.type, menu.path, this.uuid]
    
    return ['/detail', this.existing_path, this.uuid, menu.type, menu.path, this.uuid]
  }
}
