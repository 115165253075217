import { Component, ViewContainerRef } from '@angular/core';
import { TableMenuService } from 'lexi-table-menu';
import { Router, NavigationEnd, Event } from '@angular/router'
import { LexiAuthService } from 'lexi-auth';
import { MenuHelperService } from 'menu-helper';
import { ApiService } from 'lexi-api';
import { LexiNotifyService } from 'lexi-notify';
import { LexiTableService } from 'lexi-table';
declare var PerfectScrollbar: any;
declare var $: any;  

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Tadika';

  private ps : any = null;

  private headerMenu:any = {};
  private isLogin:any;
  constructor(
    private menu: TableMenuService, 
    private menuHelper : MenuHelperService, 
    private api: ApiService,
    private router: Router, 
    private tableService: LexiTableService,
    private apiService: ApiService,
    private notify: LexiNotifyService,
    private auth: LexiAuthService
  ) {
    this.loadFunction();
    this.api.valueAppender("token", this.auth.getToken())

    this.auth.getLoginStatus.subscribe(async (v:any) => {
      if (v) {    
        this.isLogin = true;
        this.loadMenu()
      }else{
        this.isLogin = false;
        this.menuHelper.reset()
      }
    })
    

    let func = async(vcr:ViewContainerRef, uuid:string, parentUUID:string) => {
      let component = ((await import("whb-task")).ProgressDashboardComponent)
      let ci = vcr.createComponent(component);
          ci.instance.task_uuid = uuid;        
    }
    this.menu.append("task-progress", func);


    let func2 = async(vcr:ViewContainerRef, uuid:string, parentUUID:string) => {
      vcr.clear()
      let component = ((await import("tdb")).MediaUploadComponent)
      let ci = vcr.createComponent(component);
          ci.instance.report_uuid = uuid;        
    }
    this.menu.append("media-upload", func2);

    let func3 = async(vcr:ViewContainerRef, uuid:string, parentUUID:string) => {
      vcr.clear()
      let component = ((await import("tdb")).ReportTableViewComponent)
      let ci = vcr.createComponent(component);
          ci.instance.report_uuid = uuid;        
    }
    this.menu.append("report-table", func3);

    let func4 = async(vcr:ViewContainerRef, uuid:string, parentUUID:string) => {
      vcr.clear()
      let component = ((await import("tdb")).PackageSubscriptionComponent)
      let ci = vcr.createComponent(component);
          ci.instance.student_uuid = uuid;        
    }
    this.menu.append("package-subscription", func4);

    let func5 = async(vcr:ViewContainerRef, uuid:string, parentUUID:string) => {
      if(!vcr) {
        return;
      }
      vcr.clear()
      let component = ((await import("tdb")).ProgressReportComponent)
      let ci = vcr.createComponent(component);
          ci.instance.report_uuid = uuid;        
    }
    this.menu.append("progress-report", func5);

    let func6 = async(vcr:ViewContainerRef, uuid:string, parentUUID:string) => {
      if(!vcr) {
        return;
      }
      vcr.clear()
      let component = ((await import("tdb")).GeneralReportComponent)
      let ci = vcr.createComponent(component);
          ci.instance.report_uuid = uuid;        
    }
    this.menu.append("report-general", func6);
  
  }

  async loadFunction() {
        this.tableService.setFunction("viewTermReport", async (val:any) => {
          const rtn = await this.api.post('/task/tdb/progress-class/get', {report_uuid: val.uuid})
          this.router.navigate(['/detail/report', rtn.student.uuid, 'extend', 'progress-report', rtn.student.uuid], { state: rtn })
          console.log('pppppppp', rtn)
     })
        
  }

  


  initMenu() {
    
    const initPerfectScroll = () => {
      if (this.ps) {
        return;
      }
      
      this.ps = new PerfectScrollbar('#navbarMenu', {
        suppressScrollX: true,
        wheelSpeed: 2,
        wheelPropagation: true,
      });
    }

    if(window.matchMedia('(max-width: 991px)').matches) {
      initPerfectScroll();
    }

    $('#mainMenuOpen').on('click touchstart', () => {
      $('body').addClass('navbar-nav-show');
      $('.backdrop').addClass('show');
    });

    $('#mainMenuClose').on('click', () => {
      $('body').removeClass('navbar-nav-show');
      $('.backdrop').removeClass('show');
    });

    $('.navbar-menu .with-sub .nav-link').click(function(this: typeof $) {
      $(this).parent().toggleClass('show');
      $(this).parent().siblings().removeClass('show');

      if(window.matchMedia('(max-width: 991px)').matches) {
        initPerfectScroll();
        this.ps.update();
      }
    });

    $('.backdrop').click(function() {
      $('body').removeClass('navbar-nav-show');
      $('.backdrop').removeClass('show');
    })

    $('.nav-sub-link').click(function() {
      if(window.matchMedia('(max-width: 991px)').matches) {
        $('body').removeClass('navbar-nav-show');
        $('.backdrop').removeClass('show');
      } else {
        $('.nav-item.with-sub').removeClass('show');
      }
    });

  }

  async loadMenu() {
    
    this.headerMenu.primary_menu = [];
    // this.menuHelper.reset()
  
    const result = await this.api.post('/users/menu/get', {token: this.auth.getToken()})
    if(!this.menuHelper.get().primary_menu || this.menuHelper.get().primary_menu!.length == 0) {
      this.menuHelper.set(result.menu)
      this.headerMenu = this.menuHelper.get();
      setTimeout(() => {
        this.ps = null;
        this.initMenu();
      }, 0)  
    }
  }
}
