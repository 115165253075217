import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PostComponent } from 'lexi-dashforge-top';
import { ProfileComponent } from './profile.component';

const routes: Routes = [
  {path: "", component: PostComponent, children: [
    {path: "", component: ProfileComponent}
  ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfileRoutingModule { }
