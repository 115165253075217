import { Component, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LexiResourceModule, ResourceManagerComponent } from 'lexi-resource';

@Component({
  selector: 'lib-dropzone',
  templateUrl: './dropzone.component.html',
  styleUrls: ['./dropzone.component.css']
})
export class DropzoneComponent implements AfterViewInit {

  @ViewChild("resource", {static: true}) resource! : ResourceManagerComponent
  public ctrl:any;
  public data:any = [];

  @Input() path:any;
  @Input() public field: any = {};
  @Input() set control(val : AbstractControl) {
    if(val.value) {
      this.data = val.value
    }
    this.ctrl = (val as unknown) as NgControl
  }
  @Output() outputEvent : EventEmitter<boolean> = new EventEmitter<boolean>(); 

  constructor() { 
    // path prepand
    // path append
  }
  
  ngAfterViewInit(): void {
    this.resource.hideSideBar = true;
    this.resource.canCreateFolder = () => {return false};
    this.resource.setDefault = "/upload";

    if(this.field.mapping.path) {
      let path = (this.field.mapping.path.prepend ?? "") + (this.field[this.field.mapping.key] ?? "") + (this.field.mapping.path.append ?? "")
      this.resource.setPath = path;
    }

    if(this.field.mapping.supportedFileType) {
      this.resource.setSupportedFileType = this.field.mapping.supportedFileType
    }
     
  }

}
