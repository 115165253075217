import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './table.component';
import { TableRoutingModule } from './table.routing.module';
import { ButtonComponent } from './button/button.component';
import { FilterComponent } from './filter/filter.component';
import { FilterDateComponent } from './filter-date/filter-date.component';


@NgModule({
  declarations: [
    TableComponent,
    ButtonComponent,
    FilterComponent,
    FilterDateComponent
  ],
  imports: [
    CommonModule,
    TableRoutingModule
  ]
})
export class TableModule { }
