import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';

import { LexiDashforgeTopModule, LexiDashforgeTopOptions } from 'lexi-dashforge-top';
import { RouterModule } from '@angular/router';
import { WhbDashboardMenuModule } from 'whb-dashboard';
import { LexiChartModule } from 'lexi-chart';
import { LexiTranslationCoreModule } from 'lexi-translation';
import { TestComponent } from './test/test.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { GoogleMapsModule } from '@angular/google-maps';

const config : LexiDashforgeTopOptions = {
  app_title : "Tadika&nbsp;<span>Mutiara</span>",
  app_desc : "Tadika Mutiara",
  company_name: "Tadika Mutiara",
  web_url: "https://lexiglobal.my",
}

@NgModule({
  declarations: [
    AppComponent,
    TestComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    WhbDashboardMenuModule,
    LexiDashforgeTopModule.forRoot(config),
    LexiChartModule,
    LexiTranslationCoreModule,
    NgSelectModule,
    GoogleMapsModule
  ],
  providers: [
    {provide: "environment", useValue: environment}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
