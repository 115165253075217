import { ElementRef, ViewChild, Input } from '@angular/core';
import { Renderer2 } from '@angular/core';
import { ViewContainerRef } from '@angular/core';
import { AfterViewInit } from '@angular/core';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'lexi-api';
import { filter } from 'projects/whb-dashboard/node_modules/rxjs/dist/types';
import { LexiTableService } from '../lexi-table.service';

@Component({
  selector: 'lib-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})

export class TableComponent implements AfterViewInit {

  @Input() set setTableOption(table_option:TableOption) {
    if(table_option) {
      this.table_option = {...this.table_option, ...table_option};
    }
  }
  
  @Input() set setTableIdentifier(table_identifier:string) {
    if(table_identifier) {
      this.table_identifier = table_identifier;
      this.table_identifier_guard = table_identifier;
    }
  }

  private table_identifier_guard: string | null = null

  table_identifier: string = "";
  public table_uuid:any;
  public structure:any = {};
  public loading: boolean = true;
  public table_not_found: boolean= false;
  public subview: boolean = false;
  private subview_identifier:any;
  private subview_uuid:any;
  public searchValue:any;
  public table_option: TableOption = {method : "normal", wrapperClass: "col-12 col-md-12"}

  @ViewChild("buttonsView", {read: ViewContainerRef}) buttons!: ViewContainerRef;
  @ViewChild("table", {static: true}) table!: ElementRef;
  @ViewChild("filtersView", {read: ViewContainerRef}) filters!: ViewContainerRef;
  @ViewChild("filtersDateView", {read: ViewContainerRef}) filtersDate!: ViewContainerRef;

  constructor(private route: ActivatedRoute, private apiService: ApiService, private renderer: Renderer2, private router: Router, private tableSerivce: LexiTableService) {

    let snapshot:any = route.snapshot.data;

    if(snapshot.subview) {
      this.subview = snapshot.subview;
      this.route.parent?.params.subscribe((a:any) => {
        this.subview_identifier = a.identifier;
        this.subview_uuid = a.uuid;
      })
    }
    
    this.route.params.subscribe(async (val: any) => {
      if(!val.table_identifier || this.table_identifier_guard) {
        return;
      }

      this.table_identifier = val.table_identifier;
      this.table_uuid = val.uuid;
      await this.getTable();
    })
  }

  ngAfterViewInit(): void {
    this.getTable();
  }

  resetTable() {
    this.table_not_found = false;
    this.loading = true;
    this.table.nativeElement.innerHTML = "";
    this.buttons.clear();
    this.filters.clear();
    this.filtersDate.clear();
    this.structure = {}
  }

  async getTable() {

    if(!this.table) {
      return;
    }

    this.resetTable();
    var data:any = {}
        data['identifier'] = this.table_identifier;
        data['uuid'] = this.table_uuid;
        data['filter'] = {}
        if(this.subview_identifier && this.subview_uuid){
          data['filter']['identifier'] = this.subview_identifier;
          data['filter']['uuid'] = this.subview_uuid;
        }
        data['filter']['search'] = this.searchValue;

    let structure = await this.apiService.post("/table/structure", data);

    if(!structure.status) {
      this.table_not_found = true;
      this.loading = false;
      return;
    }

    this.structure = structure.structure

    await this.createButtons();
    await this.createFilters();
    await this.generateTable();
    if(this.structure.filter_date && this.structure.filter_date == 1){
      await this.createFiltersDate();
    }
    this.loading = false;
  }

  async createButtons() {

    if(!this.structure.buttons || this.structure.buttons == 0){
      return;
    }

    this.buttons.clear();

    for(var x = 0; x < this.structure.buttons.length; x++) {

      let data = this.structure.buttons[x];
      data.subview = this.subview;

      const buttonComponent = ((await import("./button/button.component")).ButtonComponent)
      const buttonComponentInstance = this.buttons.createComponent(buttonComponent);
      this.renderer.addClass(buttonComponentInstance.location.nativeElement, "me-3")

      buttonComponentInstance.instance.button = data; 
    }

  }

  async createFilters() {
    if(!this.structure.filter || this.structure.filter.length == 0){
      return;
    }

    this.filters.clear();

    const filterComponent = ((await import("./filter/filter.component")).FilterComponent)
    const filterComponentInstance = this.filters.createComponent(filterComponent);
    this.renderer.addClass(filterComponentInstance.location.nativeElement, "me-3")

    filterComponentInstance.instance.setFilter = this.searchValue;

    filterComponentInstance.instance.outputEvent.subscribe((val:any) =>{ 
      this.searchValue = val;
      this.getTable()
    })

  }

  async createFiltersDate() {
    this.filtersDate.clear();

    const filterComponent = ((await import("./filter-date/filter-date.component")).FilterDateComponent)
    const filterComponentInstance = this.filtersDate.createComponent(filterComponent);
    this.renderer.addClass(filterComponentInstance.location.nativeElement, "me-3")

    filterComponentInstance.instance.outputEvent.subscribe((val:any) =>{ 
      // this.searchValue = val;
      this.getTable()
    })
  }

  async generateTable() {
    if(!this.structure.fields || this.structure.fields.length == 0) {
      return;
    }

    let table = document.createElement("table");

    this.renderer.addClass(table, "table");
    this.renderer.addClass(table, "table-striped");
    this.renderer.addClass(table, "table-sm");

    let head = document.createElement("tr");
    
    for(var x = 0; x < this.structure.fields.length; x++) {

      let current = this.structure.fields[x]

      if(!current.table_view) {
        continue;
      }
      
      let field = document.createElement("th");
      this.renderer.addClass(field, "bg-primary");
      this.renderer.addClass(field, "text-light");
      field.innerHTML = current['title']
      head.append(field)
    }

    let thead = document.createElement("thead");
    thead.append(head)
    table.append(thead);

    let tbody = document.createElement("tbody")

    for(var y = 0; y < this.structure.data.length; y++) {
      let row = document.createElement("tr");
      let data = this.structure.data[y]
      for(var x = 0; x < this.structure.fields.length; x++) {

        let current = this.structure.fields[x]

        if(!current.table_view) {
          continue;
        }

        let cell = document.createElement("td");

        if(current['support'] && current['support']['style'] && current['support']['style'].length > 0) {
          let styles = current['support']['style'];

          for(var z = 0; z < styles.length; z++) {
            this.renderer.addClass(cell, styles[z]);
          }
        }

        var value: string = "";
        switch(current['type']) {

          case "index":
            value = (y + 1).toString();
            cell.innerHTML = value;
          break;

          case "status":
            let status = this.structure.data[y][current['mapping']['key']];
            value = !status || status == 0 ? 'Disabled' : "Active";
            cell.innerHTML = value;
          break;

          case "price":
            let price = this.structure.data[y][current['mapping']['key']];
            value = "<span>RM</span><span>" + price.toFixed(2) + "</span>"

            this.renderer.addClass(cell, "d-flex")
            this.renderer.addClass(cell, "flex-row")
            this.renderer.addClass(cell, "justify-content-center")
            cell.innerHTML = value;
          break;

          case "date":
            if(data[current['mapping']['key']]) {
              const date = new Date(data[current['mapping']['key']]);
              value = ("0" + date.getDate()).slice(-2) + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-"  + date.getFullYear();
            }
            cell.innerHTML = value;
          break;

          case "function":
            const func = document.createElement("a")
            func.innerHTML = current['title']
            func.onclick = this.tableSerivce.getFunction(current['mapping']['key'], data);
            func.href = "javascript:void(0)"
            cell.append(func)
          break;

          case "link":
            const view = document.createElement("a")
            view.innerHTML = current['title']
            view.onclick = () => {this.link(current['mapping']['key'] + "/" + this.table_identifier + "/" + data[current['mapping']['variable']], data)}
            view.href = "javascript:void(0)"
            cell.append(view)
          break;

          case "link-no-table-mapping":
            if(current['mapping']['to_groupLink']){
              data['skip_subview'] = true;
            }

            const viewnomapping = document.createElement("a")
            viewnomapping.innerHTML = current['title']
            viewnomapping.onclick = () => {this.link(current['mapping']['key'] + "/" + data[current['mapping']['variable']], data)}
            viewnomapping.href = "javascript:void(0)"
            cell.append(viewnomapping)
          break;

          case "group-link":
            const viewEI = document.createElement("a")
            viewEI.innerHTML = current['title']
            viewEI.onclick = () => {this.link(current['mapping']['key'] + "/" + data[current['mapping']['variable']], data)}
            viewEI.href = "javascript:void(0)"
            cell.append(viewEI)
          break;

          case "delete":
            const remove = document.createElement("a")
            remove.innerHTML = current['title']
            remove.onclick = () => {this.remove(current['mapping']['key'], data[current['mapping']['variable']])}
            remove.href = "javascript:void(0)"
            cell.append(remove)
          break;

          case "whb-mobile":
            value = this.structure.data[y][current['mapping']['key']]
            // let index = value.length - 4;
            // // cell.innerHTML = value.substring(0, index) + " " + value.substring(index);;
            cell.innerHTML = this.standardizePhoneNumber(value);
          break;

          default:
          case "string":
            value = this.structure.data[y][current['mapping']['key']]
            cell.innerHTML = value;
          break;
        }

        row.append(cell)
      }
      tbody.append(row)
    }

    table.append(tbody)

    this.table.nativeElement.append(table);

  }

  link(path:string, data:any = {}) {
    if(this.subview && !data['skip_subview']) {
      let actions = path.split("/");

      let routes = ['detail', this.subview_identifier, this.subview_uuid, actions[0], actions[1], actions[2]]

      this.router.navigate(routes, {state: {data, fields: this.structure.fields}})
      return;
    }
    this.router.navigate([path], {state: {data, fields: this.structure.fields}})
  }

  async remove(path: string, identifier: string) {
    await this.apiService.post(path, {table_identifier: this.table_identifier, uuid: identifier});
    await this.getTable();
  }

  standardizePhoneNumber(phoneNumber: any) { 
    var phoneNumber = phoneNumber.replace(/\D/g, ""); 
    if ((phoneNumber.length == 11 || phoneNumber.length == 10) && phoneNumber[0] == '0') { 
      phoneNumber = "+6" + phoneNumber; 
    } else if ((phoneNumber.length == 12 || phoneNumber.length == 11) && phoneNumber[0] == '6') { 
      phoneNumber = "+" + phoneNumber; 
    } else{
      phoneNumber = "+60" + phoneNumber; 
    }
    return phoneNumber.slice(0, 5) + '-' + phoneNumber.slice(5, phoneNumber.length - 4) + " " + phoneNumber.slice(phoneNumber.length - 4); 
  }
}

export interface TableOption {
  method?:string, // normal, popup, embed
  wrapperClass?: string
}