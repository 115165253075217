import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewRoutingModule } from './view.routing.module';
import { RouterModule } from '@angular/router';
import { ViewComponent } from './view.component';
import { LexiTableModule } from 'lexi-table';
import { LexiFormModule } from 'lexi-form';
import { ExtendViewComponent } from '../extend-view/extend-view.component';

@NgModule({
  declarations: [ViewComponent, ExtendViewComponent],
  imports: [
    RouterModule,
    CommonModule,
    ViewRoutingModule,
    LexiTableModule,
    LexiFormModule
  ]
})
export class ViewModule { }
