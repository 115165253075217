import { Component, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'lib-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent {

  public search:any;
  public showAll = true;

  @Input() set setFilter(v:any) {
    if (v) {
      (<HTMLInputElement>document.getElementById("search")).value = v;
      this.showAll = false;
    }
  }

  @ViewChild("filterView", {static: true}) filterView!: ElementRef;

  @Output() outputEvent : EventEmitter<boolean> = new EventEmitter<boolean>(); 

  constructor() { }

  detectInput() {
    this.search = (<HTMLInputElement>document.getElementById("search")).value
    this.showAll = this.search.length > 0 ? false : true
    this.outputEvent.emit(this.search)
  }

  reset() {
    this.showAll = true;
    this.outputEvent.emit();
  }
}
