import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PositiveAuthGuard } from 'lexi-auth'
import { PostComponent } from 'lexi-dashforge-top';

const routes: Routes = [
  {path: "", redirectTo: 'auth', pathMatch: 'full' },
  {path: "dashboard", loadChildren: () => import("whb-dashboard").then(m => m.WhbDashboardModule), canActivate: [PositiveAuthGuard]},
  {path: "user/profile", loadChildren: () => import("whb-dashboard").then(m => m.ProfileModule), canActivate: [PositiveAuthGuard]},
  {path: "", component: PostComponent, children: [
    {path: "account", loadChildren: () => import("lexi-table").then(  m => m.LexiTableModule), canActivate: [PositiveAuthGuard]},
    {path: "task", loadChildren: () => import("lexi-table").then(m => m.LexiTableModule), canActivate: [PositiveAuthGuard]},
    {path: "task-map", loadChildren: () => import("whb-task").then(m => m.WhbTaskModule), canActivate: [PositiveAuthGuard]},
    {path: "inventory", loadChildren: () => import("lexi-table").then(m => m.LexiTableModule), canActivate: [PositiveAuthGuard]},
    {path: "hr", loadChildren: () => import("lexi-table").then(m => m.LexiTableModule), canActivate: [PositiveAuthGuard]},
    {path: "setting", loadChildren: () => import("lexi-table").then(m => m.LexiTableModule), canActivate: [PositiveAuthGuard]},
    {path: "form", loadChildren: () => import("lexi-form").then(m => m.LexiFormModule), canActivate: [PositiveAuthGuard]},
    {path: "view", loadChildren: () => import("lexi-view").then(m => m.LexiViewModule), canActivate: [PositiveAuthGuard]},
    {path: "detail", loadChildren: () => import("lexi-table-menu").then(m => m.LexiTableMenuModule), canActivate: [PositiveAuthGuard]},  
    {path: "file", loadChildren: () => import("lexi-table").then(m => m.LexiTableModule), canActivate: [PositiveAuthGuard]},
    {path: "test", loadChildren: () => import("lexi-chart").then(m => m.LexiChartModule), canActivate: [PositiveAuthGuard]},  
    {path: "notification", loadChildren: () => import("whb-notification").then(m => m.WhbNotificationModule), canActivate: [PositiveAuthGuard]},
    {path: "tdb", loadChildren: () => import("tdb").then(m => m.TdbModule), canActivate: [PositiveAuthGuard]},
    {path: "list", loadChildren: () => import("lexi-table").then(m => m.LexiTableModule), canActivate: [PositiveAuthGuard]}
  ]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
