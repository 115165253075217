import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';

@Component({
  selector: 'lib-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.css']
})
export class CheckboxComponent {

  public ctrl:any;

  @Input() field: any = {};
  @Input() set control(val : AbstractControl) {
    this.ctrl = (val as unknown) as NgControl
  }
  @Output() outputEvent : EventEmitter<boolean> = new EventEmitter<boolean>(); 

  constructor() { }

}
