import { Component, AfterViewChecked, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'lib-filter-date',
  templateUrl: './filter-date.component.html',
  styleUrls: ['./filter-date.component.css']
})
export class FilterDateComponent implements AfterViewChecked {

  public search:any = {};

  @ViewChild("filterView", {static: true}) filterView!: ElementRef;

  @Output() outputEvent : EventEmitter<boolean> = new EventEmitter<boolean>(); 

  constructor() { }

  ngAfterViewChecked(): void {
    //Called after every check of the component's view. Applies to components only.
    //Add 'implements AfterViewChecked' to the class.
    
    // let date = new Date();
    // let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    // this.search.date_from = date.getFullYear() +"-"+ ("0" + (date.getMonth() + 1)).slice(-2) +"-01"
    // this.search.date_to = date.getFullYear() +"-"+ ("0" + (date.getMonth() + 1)).slice(-2) +"-"+ ("0" + lastDay.getDate()).slice(-2);
  }

  detectInput() {
    this.search.date_from = (<HTMLInputElement>document.getElementById("dateFrom")).value
    this.search.date_to = (<HTMLInputElement>document.getElementById("dateTo")).value

    this.outputEvent.emit(this.search)
  }

}
