<div class="container-fluid">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-6 col-lg-6">
            <div class="card mb-4 dashboard-card">
                <div class="card-body">
                    <div class="d-flex flex-row">
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7.753 18.305c-.261-.586-.789-.991-1.871-1.241-2.293-.529-4.428-.993-3.393-2.945 3.145-5.942.833-9.119-2.489-9.119-3.388 0-5.644 3.299-2.489 9.119 1.066 1.964-1.148 2.427-3.393 2.945-1.084.25-1.608.658-1.867 1.246-1.405-1.723-2.251-3.919-2.251-6.31 0-5.514 4.486-10 10-10s10 4.486 10 10c0 2.389-.845 4.583-2.247 6.305z"/></svg>
                        <div class="d-flex flex-column ms-3 align-self-center">
                            <h3 class="text-primary"><b>{{today}} {{current}}</b></h3>
                            <h6>WELCOME, {{user.name | uppercase}}</h6>
                            <h4>{{user.email}}</h4>
                        </div>
                    </div>
                    <hr>
                    <ng-container *ngIf="!data.class_attendance">
                        <!-- Principle -->
                        <div class="d-flex flex-column">
                            <button class="btn btn-success w-100" [disabled]="data.clock_in" (click)="clockIn()">Clock In <span *ngIf="data.clock_in"> : {{data.clock_in}}</span></button>
                            <button class="mt-2 btn btn-danger w-100" *ngIf="data.clock_in" (click)="clockOut()">Clock Out</button>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="data.class_attendance">
                        <!-- Teacher -->
                        <div class="d-flex flex-column">
                            <button class="btn btn-success w-100" [disabled]="data.clock_in" (click)="clockIn()">Clock In <span *ngIf="data.clock_in"> : {{data.clock_in}}</span></button>
                            <button class="mt-2 btn btn-danger w-100" *ngIf="data.clock_in" (click)="clockOut()">Clock Out</button>
                        </div>
                    </ng-container>
                </div>
            </div>

            <div class="card mb-4 dashboard-card" *ngIf="data.class_attendance">
                <div class="card-body">
                    <h4><b>Student Clock In / Out</b></h4>
                    <select class="form-control form-control-sm" [(ngModel)]="data.kid_in">
                        <option [value]="null" selected disabled>Select A Student</option>
                        <option *ngFor="let student of data.class_attendance" [value]="student.id">{{student.name}}</option>
                    </select>
                    <button class="btn btn-success w-100 my-3" (click)="clockInStudent()">Clock In</button>

                    <select class="form-control form-control-sm" *ngIf="data.clock_out == 1" [(ngModel)]="data.kid_out">
                        <option [value]="null" selected disabled>Select A Student</option>
                        <option *ngFor="let student of data.class_clockout" [value]="student.clock_id">{{student.name}}</option>
                    </select>
                    <button class="btn btn-danger w-100 mt-3" *ngIf="data.clock_out == 1" (click)="clockOutStudent()">Clock Out</button>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6">
            <ng-container *ngIf="data.group_id != 3">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-4">
                        <div class="card mb-4 h-100 dashboard-card">
                            <div class="card-body d-flex flex-column justify-content-between">
                                <div>
                                    <h3 class="text-secondary"><b>{{data.student_month}}</b></h3>
                                    <h5><b>Registered Students</b><br><small class="text-muted">(Current Month)</small></h5>
                                </div>
                                <div>
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item d-flex justify-content-between py-1" *ngFor="let branch of data.branch;">
                                            <span>{{branch.branch_name}}</span>
                                            <span class="text-primary"><b>{{branch.branch_student_month}}</b></span>
                                        </li>
                                    </ul>
                                    <button class="btn btn-outline-dark btn-sm w-100 mt-3" (click)="cancel()">VIEW</button> 
                                </div> 
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-4">
                        <div class="card mb-4 h-100 dashboard-card">
                            <div class="card-body d-flex flex-column justify-content-between">
                                <div>
                                    <h3 class="text-secondary"><b>{{data.student}}</b></h3>
                                    <h5><b>Total Students</b></h5>
                                </div>
                                <div>
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item d-flex justify-content-between py-1" *ngFor="let branch of data.branch;">
                                            <span>{{branch.branch_name}}</span>
                                            <span class="text-primary"><b>{{branch.branch_student}}</b></span>
                                        </li>
                                    </ul>
                                    <button class="btn btn-outline-dark btn-sm w-100 mt-3" (click)="cancel()">VIEW</button>  
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-4">
                        <div class="card mb-4 h-100 dashboard-card">
                            <div class="card-body d-flex flex-column justify-content-between">
                                <div>
                                    <h3 class="text-secondary"><b>{{data.teacher}}</b></h3>
                                    <h5><b>Total Teachers</b></h5>
                                </div>
                                <div>
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item d-flex justify-content-between py-1" *ngFor="let branch of data.branch;">
                                            <span>{{branch.branch_name}}</span>
                                            <span class="text-primary"><b>{{branch.branch_teacher}}</b></span>
                                        </li>
                                    </ul>
                                    <button class="btn btn-outline-dark btn-sm w-100 mt-3" (click)="viewteacher()">VIEW</button>  
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-4">
                        <div class="card mb-4 h-100 dashboard-card">
                            <div class="card-body d-flex flex-column justify-content-between">
                                <div>
                                    <h3 class="text-secondary"><b>{{data.invoice}}</b></h3>
                                    <h5><b>Pending Invoice</b></h5>
                                </div>
                                <div>
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item d-flex justify-content-between py-1" *ngFor="let branch of data.branch;">
                                            <span>{{branch.branch_name}}</span>
                                            <span class="text-primary"><b>{{branch.branch_invoice}}</b></span>
                                        </li>
                                    </ul>
                                    <button class="btn btn-outline-dark btn-sm w-100 mt-3" (click)="viewinvoice()">VIEW</button>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            
            <ng-container *ngIf="data.group_id == 3">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-4">
                        <div class="card mb-4 h-100 dashboard-card">
                            <div class="card-body d-flex flex-column justify-content-between">
                                <div>
                                    <h3 class="text-secondary"><b>{{data.student_month}}</b></h3>
                                    <h5><b>Registered Students</b><br><small class="text-muted">(Current Month)</small></h5>
                                </div>
                                <div>
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item d-flex justify-content-between py-1" *ngFor="let branch of data.branch;">
                                            <span>{{branch.branch_name}}</span>
                                            <span class="text-primary"><b>{{branch.branch_student_month}}</b></span>
                                        </li>
                                    </ul>
                                    <button class="btn btn-outline-dark btn-sm w-100 mt-3" (click)="cancel()">VIEW</button> 
                                </div> 
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-4">
                        <div class="card mb-4 h-100 dashboard-card">
                            <div class="card-body d-flex flex-column justify-content-between">
                                <div>
                                    <h3 class="text-secondary"><b>{{data.student}}</b></h3>
                                    <h5><b>Total Students</b></h5>
                                </div>
                                <div>
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item d-flex justify-content-between py-1" *ngFor="let branch of data.branch;">
                                            <span>{{branch.branch_name}}</span>
                                            <span class="text-primary"><b>{{branch.branch_student}}</b></span>
                                        </li>
                                    </ul>
                                    <button class="btn btn-outline-dark btn-sm w-100 mt-3" (click)="cancel()">VIEW</button>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>




