import { ElementRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'lib-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent {

  public btn: any = {};
  private subview_identifier: any;
  private subview_uuid: any;

  @ViewChild("buttonView", {static: true}) buttonView!: ElementRef;

  @Input() set button(val: any) {
    this.btn = val;

    if(val.subview) {
      this.activatedRoute.parent?.params.subscribe((a:any) => {
        this.subview_identifier = a.identifier;
        this.subview_uuid = a.uuid;
      })
    }

    if (val.type == 'link' && val.action) {
      this.buttonView.nativeElement.onclick = () => {

        if(val.subview && this.subview_identifier && this.subview_uuid) {

          let actions = val.action.split("/");

          let routes = ['detail', this.subview_identifier, this.subview_uuid, actions[1], actions[2]]

          this.router.navigate(routes, val.data || {})
        } else {
          this.router.navigate([val.action], val.data || {})
        }
      }
    }
  };

  constructor(private router: Router, private activatedRoute: ActivatedRoute) { }

}
