import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PostComponent } from 'lexi-dashforge-top';
import { DashboardComponent } from './dashboard.component';

const routes: Routes = [
  {path: "", component: PostComponent, children: [
    {path: "", component: DashboardComponent}
  ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
