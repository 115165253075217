import { NgModule } from '@angular/core';
import { WHBDashboardRoutingModule } from './whb-dashboard.routing.module';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [
  ],
  imports: [
    WHBDashboardRoutingModule,
    NgSelectModule
  ],
  exports: [
  ]
})
export class WhbDashboardModule { }
