<div class="row">
    <div class="col-12 col-sm-12 col-md-6">
        <div class="card">
            <div class="card-body">
                <section *ngIf="progress == 1">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item d-flex justify-content-between">
                            <span>Name</span>
                            <span>{{data.name}}</span>
                        </li>
                        <li class="list-group-item d-flex justify-content-between">
                            <span>Email</span>
                            <span>{{data.email}}</span>
                        </li>
                        <li class="list-group-item d-flex justify-content-between">
                            <span>Contact</span>
                            <span>{{data.mobile}}</span>
                        </li>
                        <li class="list-group-item d-flex justify-content-between">
                            <span>Position</span>
                            <span>{{data.position}}</span>
                        </li>
                    </ul>
                    <div class="d-flex justify-content-end mt-4">
                        <button class="btn btn-primary btn-sm me-2" (click)="progressTo(3)">Change Password</button>
                        <button class="btn btn-secondary btn-sm" (click)="progressTo(2)">Edit</button>
                    </div>
                </section>
                <section *ngIf="progress == 2">
                    <button class="btn btn-outline-dark btn-sm mb-4" (click)="progressTo(1)">
                        <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m9.474 5.209s-4.501 4.505-6.254 6.259c-.147.146-.22.338-.22.53s.073.384.22.53c1.752 1.754 6.252 6.257 6.252 6.257.145.145.336.217.527.217.191-.001.383-.074.53-.221.293-.293.294-.766.004-1.057l-4.976-4.976h14.692c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-14.692l4.978-4.979c.289-.289.287-.761-.006-1.054-.147-.147-.339-.221-.53-.221-.191-.001-.38.071-.525.215z" fill-rule="nonzero"/></svg>
                        Back
                    </button>
                    <div class="form-group">
                        <label>Name</label>
                        <input class="form-control" type="text" [(ngModel)]="data.name" name="name">
                    </div>
                    <div class="form-group">
                        <label>Email</label>
                        <input class="form-control" type="email" [(ngModel)]="data.email" name="email">
                    </div>
                    <div class="form-group">
                        <label>Contact</label>
                        <input class="form-control" type="text" [(ngModel)]="data.mobile" name="mobile">
                    </div>
                    <button class="btn btn-primary btn-sm w-100 mt-4" (click)="submit('data')">Save</button>
                </section>
                <section *ngIf="progress == 3">
                    <button class="btn btn-outline-dark btn-sm mb-4" (click)="progressTo(1)">
                        <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m9.474 5.209s-4.501 4.505-6.254 6.259c-.147.146-.22.338-.22.53s.073.384.22.53c1.752 1.754 6.252 6.257 6.252 6.257.145.145.336.217.527.217.191-.001.383-.074.53-.221.293-.293.294-.766.004-1.057l-4.976-4.976h14.692c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-14.692l4.978-4.979c.289-.289.287-.761-.006-1.054-.147-.147-.339-.221-.53-.221-.191-.001-.38.071-.525.215z" fill-rule="nonzero"/></svg>
                        Back
                    </button>
                    <div class="form-group">
                        <label>Password</label>
                        <input class="form-control" type="password" [(ngModel)]="data.password" name="password">
                    </div>
                    <div class="form-group">
                        <label>Confirm Password</label>
                        <input class="form-control" type="password" [(ngModel)]="data.confirm_password" name="confirm_password">
                    </div>
                    <button class="btn btn-primary btn-sm w-100 mt-4" (click)="submit('password')">Save</button>
                </section>
            </div>
        </div>
    </div>
</div>
