import { NgModule } from '@angular/core';
import { MenuHelperModule } from 'menu-helper';

const ModuleMenu = () => { return {
  
    // primary_menu: [
    //   {title: "Dashboard", path: "/dashboard"}, 
    //   {title: "Account", sub_menu: [
    //     {menu:[
    //       {title: "Contact Person", path: "/account/customer-entity"},
    //       {title: "Student", path: "/account/customer-list"},
    //     ]}
    //   ]},
    //   {title: "Report", sub_menu: [
    //     {menu: [
    //       {title: "Report", path: "/list/report"}, 
    //       {title: "Review", path: "/list/report-review"}, 
    //     ]}
    //   ]},
    //   {title: "HR", sub_menu: [
    //     {menu:[
    //       {title: "User", path: "/hr/user"},
    //       {title: "Teacher", path: "/hr/teacher"},
    //     ]}
    //   ]},
    //   {title: "Setting", sub_menu: [
    //     {menu: [
    //         {title: "Access Management", path: "/setting/hr-position"},
    //         {title: "Relation Type", path: "/setting/relation-type"},
    //         {title: "Race Type", path: "/setting/race-type"},
    //         {title: "Nationality Type", path: "/setting/nationality"},
    //       ],
    //       title: "Customer/Staff"
    //     },
    //     {menu: [
    //         {title: "Branch", path: "/setting/branch"},
    //         {title: "Classrom", path: "/setting/classroom"},
    //         {title: "Subject", path: "/setting/subject"}
    //       ],
    //       title: "Area"
    //     }
    //   ]},
    // ]
  }
}

@NgModule({
  declarations: [
  ],
  imports: [
    MenuHelperModule.forRoot({
      func: ModuleMenu,
      key: "dashboard"
    })
  ],
  exports: [
  ]
})
export class WhbDashboardMenuModule {
  constructor() {}
}
