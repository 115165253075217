import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormComponent } from './form.component';
import { FormsModule } from '@angular/forms';
import { FormRoutingModule } from './form.routing.module';
import { StatusComponent } from '../component/status/status.component';
import { SelectComponent } from '../component/select/select.component';
import { TextComponent } from '../component/text/text.component';
import { HiddenComponent } from '../component/hidden/hidden.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TextareaComponent } from '../component/textarea/textarea.component';
import { AutocompleteComponent } from '../component/autocomplete/autocomplete.component';
import { ReadonlyComponent } from '../component/readonly/readonly.component';
import { CheckboxComponent } from '../component/checkbox/checkbox.component';
import { RadioComponent } from '../component/radio/radio.component';
import { FileUploaderComponent } from '../component/file-uploader/file-uploader.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { LexiResourceModule } from 'lexi-resource';
import { LexiWysiwygModule } from 'lexi-wysiwyg';
import { DropzoneComponent } from '../component/dropzone/dropzone.component';
import { WysiwygComponent } from '../component/wysiwyg/wysiwyg.component';
import { MapComponent } from '../component/map/map.component';
import { MultiCheckboxComponent } from '../component/multi-checkbox/multi-checkbox.component';
import { MultiSelectComponent } from '../component/multi-select/multi-select.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MobileComponent } from '../component/mobile/mobile.component';
import { YesnoComponent } from '../component/yesno/yesno.component';
import { LabelComponent } from '../component/label/label.component';

@NgModule({
  declarations: [
    FormComponent,
    TextComponent,
    StatusComponent,
    SelectComponent,
    HiddenComponent,
    TextareaComponent,
    ReadonlyComponent,
    AutocompleteComponent,
    CheckboxComponent,
    RadioComponent,
    FileUploaderComponent,
    DropzoneComponent,
    WysiwygComponent,
    MapComponent,
    MultiCheckboxComponent,
    MultiSelectComponent,
    MobileComponent,
    YesnoComponent,
    LabelComponent
  ],
  imports: [
    CommonModule,
    FormRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModalModule,
    LexiResourceModule,
    LexiWysiwygModule,
    NgSelectModule
  ]
})

export class FormModule { }
