// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // api_url: "http://ns15.lexiglobal.my:7055",
  api_url: "https://education.erp.lexiglobal.my/api",

  // Authentication Setting
  token_validation_url: "/users/validate/token",
  token_user_url: "/users/validate/user",
  token_user_logout: "/users/logout",
  negative_guard_failed_redirect: ["/dashboard"],
  positive_guard_failed_redirect: ["/auth"],
  negative_child_guard_failed_redirect: ["/dashboard"],
  positive_child_guard_failed_redirect: ["/auth"],
  method_guard_failed_redirect: ["/auth"],
  user_guard_failed_redirect: ["/auth"],

  
  get_resource_api_folder_path: "/upload/list/folder",
  get_resource_api_file_path: "/upload/list/file",
  update_resource_api_folder_path: "/update/list/folder",
  update_resource_api_file_path: "/update/list/file",

  // Lexi DashforgeTop Setting
  profile_path: ["/user/profile"],
  success_login_url: ['/dashboard'],

  dsme: false,
  // Debug
  debug: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
