<!-- <div class="form-group">
    <label for="mobile_country_code">Mobile Country Code <em>*</em></label>
    <select class="form-control form-control-sm" id="mobile_country_code" (change)="countryChange($event)" [disabled]="field.editable && field.editable == 0">
        <ng-container *ngIf="options && options.length > 0">
            <option *ngFor="let option of options" [value]="option.country_code">{{option.country + ' (' + option.country_code + ')' }}</option>
        </ng-container>
        <ng-container *ngIf="!options || options.length == 0">
            <option disabled>No data found.</option>
        </ng-container>
    </select>
</div>
<div class="form-group">
    <label [for]="field.mapping.key">{{field.title}} <em *ngIf="field.required">*</em></label>
    <input [formControl]="ctrl" [type]="field.type" class="form-control form-control-sm" [id]="field.mapping.key" required>
</div> -->

<!-- <div class="input-group mb-3">
    <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">{{selected ? selected.country + ' (' + selected.country_code + ')' : 'Select Country'}}</button>
    <ul class="dropdown-menu">
      <li *ngFor="let option of options" [value]="option.country_code"><a class="dropdown-item" href="javascript: void(0)">{{option.country + ' (' + option.country_code + ')' }}</a></li>
    </ul>
    <label [for]="field.mapping.key">{{field.title}} <em *ngIf="field.required">*</em></label>
    <input [formControl]="ctrl" [type]="field.type" class="form-control form-control-sm" [id]="field.mapping.key"  [disabled]="field.editable && field.editable == 0" required>
  </div> -->
  <label [for]="field.mapping.key">{{field.title}} <em *ngIf="field.required">*</em></label>
  <div class="input-group mb-3">
    <div class="input-group-prepend">
      <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">{{selected ? selected.name + ' (' + selected.dial_code + ')' : 'Select Country'}}</button>
      <div class="dropdown-menu overflow-scroll" style="max-height: 75vh;">
        <a *ngFor="let option of options" class="dropdown-item" href="javascript: void(0)">{{option.name + ' (' + option.dial_code + ')' }}</a>
      </div>
    </div>
    <input [formControl]="ctrl" [type]="field.type" class="form-control form-control-sm" [id]="field.mapping.key"  [disabled]="field.editable && field.editable == 0" required>  </div>