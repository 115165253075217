import { Component, OnInit } from '@angular/core';
import { ApiService } from 'lexi-api'
import { CookieService } from 'ngx-cookie-service';
import { LexiAuthService } from 'lexi-auth';
@Component({
  selector: 'lib-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  public token:any;
  public today:any;
  public selectedStudent:any;
  public current:any;
  public clock_in:any;
  public todate:any;
  public formatdate:any;
  public currenttime:any;
  public data:any = {};
  public user:any = {};

  constructor(private api: ApiService, private cookieService: CookieService, private auth: LexiAuthService) { 
    this.token = this.cookieService.get('token') ? this.cookieService.get('token') : null;
    if(this.token){
      this.ngOnInit()
    }
  }

  ngOnInit(): void {
    this.initData()
  }

  async initData() {
    this.user = await this.api.post("/users/get-detail", {})
    console.log(this.user)
    let rtn = await this.api.post('/users/dashboard', { token: this.token });
    console.log(rtn)
    this.data = rtn.task;
    this.data.kid_in = "null";
    this.data.kid_out = "null";
    this.getTime(); 
  }

  async getTime() {
    const currentDate: Date = new Date();
    const dayOfWeek: number = currentDate.getDay();
    const dayOfWeekNames: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dayOfWeekName: string = dayOfWeekNames[dayOfWeek];
    
    const day: number = currentDate.getDate();
    const month: number = currentDate.getMonth() + 1;
    const year: number = currentDate.getFullYear();
    const hours: number = currentDate.getHours();
    const minutes: string = currentDate.getMinutes().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false});
    const seconds: string = currentDate.getSeconds().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false});

    const formattedDateTime: string = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;

    const currentDateTimeElement: HTMLElement | null = document.getElementById('currentDateTime');
    if (currentDateTimeElement) {
        currentDateTimeElement.innerHTML = formattedDateTime;
    }
    const date: string = `${year}-${month}-${day}`;
    const formatdate: string = `${day}-${month}-${year}`;
    const time: string = `${hours}:${minutes}:${seconds}`;
    this.current = formattedDateTime;
    this.todate = date;
    this.currenttime = time;
    this.today = dayOfWeekName;
    this.formatdate = formatdate;

    setTimeout(() => {
        this.getTime();
    }, 1000);  
}

async clockIn() {
  let rtn = await this.api.post('/users/dashboard/clock-in', {token: this.token, date: this.todate, time: this.currenttime});
  this.clock_in = rtn;
  this.initData(); 
}

async clockOut() {
  let rtn = await this.api.post('/users/dashboard/clock-out', {token: this.token, date: this.todate, time: this.currenttime, clock_id: this.data.clock_id});
  this.clock_in = rtn;
  this.initData(); 
}

async clockInStudent() {
  let rtn = await this.api.post('/users/dashboard/clock-in/student', {token: this.token, date: this.todate, time: this.currenttime, student: this.data.kid_in});
  this.clock_in = rtn;
  this.initData(); 
}

async clockOutStudent() {
  let rtn = await this.api.post('/users/dashboard/clock-out/student', {token: this.token, date: this.todate, time: this.currenttime, clock_id: this.data.kid_out});
  this.clock_in = rtn;
  this.initData(); 
  // this.auth.redirect(['/dashboard'])
}

cancel(){
  this.auth.redirect(['/account/student-list'])
}

viewteacher(){
  this.auth.redirect(['/hr/teacher'])
}

viewinvoice(){
  this.auth.redirect(['/list/report'])
}

}
