import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResourceManagerComponent } from 'lexi-resource';

@Component({
  selector: 'lib-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.css']
})
export class FileUploaderComponent {

  public ctrl:any;
  public data:any = [];

  @Input() public field: any = {};
  @Input() set control(val : AbstractControl) {
    if(val.value) {
      this.data = val.value
    }
    this.ctrl = (val as unknown) as NgControl
  }
  @Output() outputEvent : EventEmitter<boolean> = new EventEmitter<boolean>(); 

  constructor(private ngbModal: NgbModal) { }
  
  showResources(key?:string) {
    let modal = this.ngbModal.open(ResourceManagerComponent, {size: 'xl'});
    modal.componentInstance.setDefault = "/upload";

    modal.componentInstance.selectType = this.field.mapping.selectType ?? "single"

    modal.componentInstance.setSupportedFileType = ['image/jpg', 'image/jpeg', 'image/png', 'video/mp4', 'video/ogg', 'video/avi', 'video/mov', 'application/pdf'];
    modal.componentInstance.getSelected.subscribe((data:any) => {

      this.ctrl.setValue(data);

      this.data = data;
     
      modal.close();

    })
  }

  removeItem(index: number){
    this.data.splice(index, 1)
  }

}
