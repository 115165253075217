import { Component, Input } from '@angular/core';
import { NgControl, AbstractControl } from '@angular/forms';
import { Console } from 'console';

@Component({
  selector: 'lib-wysiwyg',
  templateUrl: './wysiwyg.component.html',
  styleUrls: ['./wysiwyg.component.css']
})
export class WysiwygComponent{


  public ctrl:any;
  public value:any;

  @Input() field: any = {};
  @Input() set control(val : AbstractControl) {
    this.ctrl = (val as unknown) as NgControl
    this.value = this.ctrl.value;
  }


  constructor() { }

  getData(data:any) {

    this.value = data;
    this.ctrl.setValue(data)
  }
}
