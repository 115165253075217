import { Component, AfterViewInit, ViewContainerRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'lexi-api';
import { FormControl, FormGroup,Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'lib-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})

export class FormComponent implements AfterViewInit {
  
  public isLoading:boolean = true;

  public innerPart:any;
  public form: FormGroup;
  public form_option: any = {method : "normal", wrapperClass: "col-12 col-md-4"}
  public presetToken = false;

  @Input() set setFormOption(form_option:any) {
    console.log(form_option)
    if(form_option) {
      this.form_option = {...this.form_option, ...form_option};
    }
  }

  @Input() set setFormIdentifier(val:any) {
    if(val) {
      this.form_identifier = val;
      this.loadForm()
    }
  };

  @Output() complete = new EventEmitter<any>()

  private form_identifier: any;
  private data_uuid: any;

  public loading: boolean = true;
  public structure : any = {};
  public subview:boolean = false;
  public subview_identifier:any;
  public subview_uuid:any;
  public token:any;

  @ViewChild("wrapper", {read: ViewContainerRef}) wrapper!: ViewContainerRef;

  @Input() set setWrapper(vcr: ViewContainerRef) {
    this.wrapper = vcr;
  }

  @Input() set setStructure(val:any) {
    this.structure = val;
    this.customLoadForm();
  } 

  constructor(private api: ApiService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService) {
    this.form = new FormGroup({});

    let snapshot:any = activatedRoute.snapshot.data;
    if(snapshot.subview) {
      this.subview = snapshot.subview;
      this.activatedRoute.parent?.params.subscribe((a:any) => {
        this.subview_identifier = a.identifier;
        this.subview_uuid = a.uuid;
      })
    }

    this.activatedRoute.params.subscribe(async (v: any) => {
      if(v.form_identifier && (!this.form_identifier || v.form_identifier !== this.form_identifier)){
        this.form_identifier = v.form_identifier;
        this.data_uuid = v.uuid;
        this.loadForm()
      }
    })    
  }

  ngAfterViewInit(): void {
    this.isLoading = true
  }

  async customLoadForm() {
    await this.generateFormControls();
    await this.build();
  }

  async loadForm() {
    this.loading = true;
    this.structure = {}

    let structure = await this.api.post("/form/structure", {form_identifier: this.form_identifier, data_uuid: this.data_uuid})
    if(!structure.status && structure.redirect) {
      this.router.navigate([structure.redirect])
      return;
    }

    if(!structure.status) {
      return;
    }

    if(structure.width) {
      this.form_option.wrapperClass = structure.width 
    }

    this.structure = structure.structure;    

    if(this.structure.fields.length > 0) {
      this.generateFormControls();
      await this.build();
    }

    this.loading = false;
  }

  generateFormControls() {
    let formFields : any = {};

    for(var x = 0; x < this.structure.fields.length; x++) {
      let field = this.structure.fields[x];
      let value = this.structure.data[field.mapping.key] || this.structure.data[field.mapping.key] == 0 ? this.structure.data[field.mapping.key] : "";

      let fv:any = value;
      if(field.type == 'readonly') {
        fv = {};
        fv.value = value
        fv.disabled = true;
      }
      formFields[field.mapping.key] = new FormControl(fv, this.validatorBuilder(field))
    }
    
    if(this.structure.data && this.structure.data.uuid) {
      formFields['uuid'] = new FormControl(this.structure.data['uuid'], [Validators.required])
    }

    if(this.form_identifier) {
      formFields['form_identifier'] = new FormControl(this.form_identifier, [Validators.required])
    }
console.log(formFields)
    this.form = new FormGroup(formFields);
  }

  validatorBuilder(field: any) {
    let validator:any = [];
console.log(field)
    if(field.required) {
      console.log("required")
      validator.push(Validators.required)
    }

    // validator.push(isValidMalaysianIcNumber)
    // return validator;

    // validator.push(isValidMobileNumber)
    // return validator;

    // validator.push(isValidCompanyNumber)
    // return validator;

    //validator.push(isValidBusinessNumber)
    return validator;
  }

  async build() {
    this.wrapper.clear();

    let component;
    let componentInstance;
    let cacheComponentInstace:any = {}
    let autoComponentInstace:any = {}
    for(var x = 0; x < this.structure.fields.length; x++) {

      let field = this.structure.fields[x];

      if(field.mapping && field.mapping.hide_by_filter && this.subview_uuid) {
        continue;
      }

      switch(field.type) {

        case "autocomplete":
          component = ((await import("../component/autocomplete/autocomplete.component")).AutocompleteComponent);
          componentInstance = this.wrapper.createComponent(component);
          componentInstance.instance.control = this.form.controls[field.mapping.key];
          componentInstance.instance.setField = field

          cacheComponentInstace[field.mapping.key] = autoComponentInstace  
          
          componentInstance.instance.outputEvent.subscribe((val:any) =>{ 
            this.form.value[val.key] = val.val;

            if(!val || !val.togglers) {
              return;
            }
            
            for(var x = 0; x < val.togglers.length; x++) {
              if(cacheComponentInstace[val.togglers[x]]) {
                cacheComponentInstace[val.togglers[x]].instance.setRelated(this.form.value[val.key])
              }
            }
          });
        break;

        case "select":
          component = ((await import("../component/select/select.component")).SelectComponent);
          componentInstance = this.wrapper.createComponent(component);
          componentInstance.instance.control = this.form.controls[field.mapping.key];
          componentInstance.instance.field = field

          cacheComponentInstace[field.mapping.key] = componentInstance

          componentInstance.instance.outputEvent.subscribe((val:any) =>{ 
            if(!val || !val.togglers) {
              return;
            }

            for(var x = 0; x < val.togglers.length; x++) {
              if(cacheComponentInstace[val.togglers[x]]) {
                cacheComponentInstace[val.togglers[x]].instance.setRelated(this.form.value[val.key])
              }  
            }
          });

        break;

        case "multi-select":
          component = ((await import("../component/multi-select/multi-select.component")).MultiSelectComponent);
          componentInstance = this.wrapper.createComponent(component);
          componentInstance.instance.control = this.form.controls[field.mapping.key];
          componentInstance.instance.field = field

          cacheComponentInstace[field.mapping.key] = componentInstance

          componentInstance.instance.outputEvent.subscribe((val:any) =>{ 
            if(!val || !val.togglers) {
              return;
            }

            for(var x = 0; x < val.togglers.length; x++) {
              if(cacheComponentInstace[val.togglers[x]]) {
                cacheComponentInstace[val.togglers[x]].instance.setRelated(this.form.value[val.key])
              }  
            }
          });

        break;

        case "status":
          component = ((await import("../component/status/status.component")).StatusComponent);
          componentInstance = this.wrapper.createComponent(component);
          componentInstance.instance.control = this.form.controls[field.mapping.key];
          componentInstance.instance.field = field
        break;

        case "yesno":
          component = ((await import("../component/yesno/yesno.component")).YesnoComponent);
          componentInstance = this.wrapper.createComponent(component);
          componentInstance.instance.control = this.form.controls[field.mapping.key];
          componentInstance.instance.field = field
        break;

        case "wysiwyg":
          component = ((await import("../component/wysiwyg/wysiwyg.component")).WysiwygComponent);
          componentInstance = this.wrapper.createComponent(component);
          componentInstance.instance.field = field
          componentInstance.instance.control = this.form.controls[field.mapping.key];
        break;

        case "textarea":
          component = ((await import("../component/textarea/textarea.component")).TextareaComponent);
          componentInstance = this.wrapper.createComponent(component);
          componentInstance.instance.field = field
          componentInstance.instance.control = this.form.controls[field.mapping.key];
        break;

        case "map":
          component = ((await import("../component/map/map.component")).MapComponent);
          componentInstance = this.wrapper.createComponent(component);
          componentInstance.instance.field = field
          componentInstance.instance.control = this.form.controls[field.mapping.key];
        break;

        case "date":   
          if(this.form.controls[field.mapping.key].value) {
            var date = new Date(this.form.controls[field.mapping.key].value);
            this.form.controls[field.mapping.key].setValue( date.getFullYear()+"-"+("0" + (date.getMonth() + 1)).slice(-2)+"-"+("0" + (date.getDate() + 1)).slice(-2) );
          }else{
            var todayDate = new Date();
            this.form.controls[field.mapping.key].setValue( todayDate.getFullYear()+"-"+("0" + (todayDate.getMonth() + 1)).slice(-2)+"-"+("0" + (todayDate.getDate() + 1)).slice(-2) );   
          }

          component = ((await import("../component/text/text.component")).TextComponent);
          componentInstance = this.wrapper.createComponent(component);
          componentInstance.instance.field = field
          componentInstance.instance.control = this.form.controls[field.mapping.key];
        break;

        case "text":
        case "email":
        case "time":
        case "number":
        case "readonly":
        case "password":
          component = ((await import("../component/text/text.component")).TextComponent);
          componentInstance = this.wrapper.createComponent(component);
          componentInstance.instance.field = field
          componentInstance.instance.control = this.form.controls[field.mapping.key];
        break;

        case "checkbox":
          component = ((await import("../component/checkbox/checkbox.component")).CheckboxComponent);
          componentInstance = this.wrapper.createComponent(component);
          componentInstance.instance.field = field
          componentInstance.instance.control = this.form.controls[field.mapping.key];
        break;
        case "multi-checkbox":
          component = ((await import("../component/multi-checkbox/multi-checkbox.component")).MultiCheckboxComponent);
          componentInstance = this.wrapper.createComponent(component);
          componentInstance.instance.field = field
          componentInstance.instance.control = this.form.controls[field.mapping.key];
        break;
        case "radio":
          console.log("aaa")
          component = ((await import("../component/radio/radio.component")).RadioComponent);
          componentInstance = this.wrapper.createComponent(component);
          componentInstance.instance.control = this.form.controls[field.mapping.key];
          componentInstance.instance.field = field

          cacheComponentInstace[field.mapping.key] = componentInstance

          componentInstance.instance.outputEvent.subscribe((val:any) =>{ 
            if(!val || !val.togglers) {
              return;
            }

            for(var x = 0; x < val.togglers.length; x++) {
              if(cacheComponentInstace[val.togglers[x]]) {
                cacheComponentInstace[val.togglers[x]].instance.setRelated(this.form.value[val.key])
              }  
            }
          });
        break;

        case "file":
            component = ((await import("../component/file-uploader/file-uploader.component")).FileUploaderComponent);
            componentInstance = this.wrapper.createComponent(component);
            componentInstance.instance.field = field
            componentInstance.instance.control = this.form.controls[field.mapping.key];
        break;

        case "dropzone":
            component = ((await import("../component/dropzone/dropzone.component")).DropzoneComponent);
            componentInstance = this.wrapper.createComponent(component);
            componentInstance.instance.field = field
            componentInstance.instance.control = this.form.controls[field.mapping.key];
        break;

        case "mobile":
            component = ((await import("../component/mobile/mobile.component")).MobileComponent);
            componentInstance = this.wrapper.createComponent(component);
            componentInstance.instance.field = field
            componentInstance.instance.control = this.form.controls[field.mapping.key];
        break;
        case "label":
          component = ((await import("../component/label/label.component")).LabelComponent);
          componentInstance = this.wrapper.createComponent(component);
          componentInstance.instance.field = field
          componentInstance.instance.control = this.form.controls[field.mapping.key];
        break;
        default:
          console.warn("Undefined Filed Type", field.type)
        break;
      }

    };

    if(this.structure.data && this.structure.data.uuid) {
      component = ((await import("../component/hidden/hidden.component")).HiddenComponent);
      componentInstance = this.wrapper.createComponent(component);
      componentInstance.instance.data = this.structure.data['uuid'];
      componentInstance.instance.field = {mapping : {key: "uuid"}}
    }

  }

  async submit(event:any){
    this.loading = true;
    console.log(this.form)
    if(this.form.status != "VALID") {
      alert("Invalid Entry. Please check input again")
      this.loading = false;
      return;
    }

    var data:any = {};
        data['form'] = this.form.value;
    if(this.subview_identifier && this.subview_uuid){
      data['filter'] = {}
      data['filter']['identifier'] = this.subview_identifier;
      data['filter']['identifier_uuid'] = this.subview_uuid;
    }

//    data['token'] = this.token;
    
    if(localStorage.getItem("profile")) {
      data['profile_uuid'] = localStorage.getItem("profile");
    }

    let rtn = await this.api.post("/form/submit", data)
    
    if(rtn.status) {
      switch(this.form_option.method) {
        case "popup":
          var data:any = this.form.value;
          data.id = rtn.insertId
          console.log(rtn, this.form_option, "here");
          this.complete.emit(data)
        break;

        default:
          if(this.structure.redirect && this.structure.redirect_subwiew && rtn.uuid){
            this.router.navigate([this.structure.redirect+"/"+rtn.uuid+"/"+this.structure.redirect_subwiew+"/"+rtn.uuid])
            return
          }

          if(this.structure.redirect) {
            this.redirect();
          }
        break;
      }
    } else if(rtn.message) {
      console.log(rtn)
      alert(rtn.message)
    }

    this.loading = false;
  }
 
  redirect() {

    if(this.form_option.method == "popup") {
      this.complete.emit(false)
      return;
    }


    if(!this.subview) {
      this.router.navigate([this.structure.redirect])
    } else if(this.subview && this.structure.subview_off == 0){
      this.router.navigate(['/detail/'+this.subview_identifier +'/'+ this.subview_uuid +'/list/'+ this.form_identifier +"/"+ this.data_uuid])      
    } else {
      alert('Data update successful.')
    }
  }
}