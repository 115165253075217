import { NgModule } from '@angular/core';
import { LexiFormRoutingModule } from './lexi-form.routing.module';

@NgModule({
  declarations: [
  ],
  imports: [
    LexiFormRoutingModule
  ],
  exports: [
    
  ]
})

export class LexiFormModule { }
