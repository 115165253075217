import { NgModule } from '@angular/core';
import { LexiTableMenuRoutingModule } from './lexi-table-menu.routing.module';
import { LexiTableModule } from 'lexi-table';
import { LexiFormModule } from 'lexi-form';



@NgModule({
  declarations: [
  ],
  imports: [
    LexiTableMenuRoutingModule,
    LexiTableModule,
    LexiFormModule
  ],
  exports: [
  ]
})
export class LexiTableMenuModule { }
