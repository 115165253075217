import { Component, Input } from '@angular/core';
import { NgControl, AbstractControl } from '@angular/forms';

@Component({
  selector: 'lib-readonly',
  templateUrl: './readonly.component.html',
  styleUrls: ['./readonly.component.css']
})
export class ReadonlyComponent {
  public ctrl:any;

  @Input() field: any = {};
  @Input() set control(val : AbstractControl) {
    this.ctrl = (val as unknown) as NgControl
  }
  

}
