import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-hidden',
  templateUrl: './hidden.component.html',
  styleUrls: ['./hidden.component.css']
})
export class HiddenComponent {

  @Input() field: any = {};
  @Input() data: any;

  constructor() { }

}
