import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormComponent } from 'lexi-form';
import { ViewComponent } from './view.component';
import { TableComponent } from 'lexi-table';
import { ExtendViewComponent } from '../extend-view/extend-view.component';

const routes: Routes = [
  {
    path: "", children: [
      {path: "", component: ViewComponent, children: [
        {path: "form/:form_identifier", component: FormComponent, data: {subview: true}},
        {path: "form/:form_identifier/:uuid", component: FormComponent, data: {subview: true}},
        {path: "list/:table_identifier", component: TableComponent, data: {subview: true}},
        {path: "list/:table_identifier/:uuid", component: TableComponent, data: {subview: true}},
        {path: "extend/:extand_identifier/:uuid", component: ExtendViewComponent, data: {subview: true}}
      ]},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ViewRoutingModule { }
