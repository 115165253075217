import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';

@Component({
  selector: 'lib-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css']
})
export class SelectComponent {

  public ctrl:any;
  public fields:any;
  public options:any = [];

  // @Input() field: any = {};
  @Input() setRelated(val:any){
    this.options = [];
    
    this.options = this.fields.options.filter((item:any) => {
        return item['related'] == val;
      });
  };
  @Input() set field(val:any) {
    this.fields = val
    this.options = this.fields.options;
  }
  @Input() set control(val : AbstractControl) {
    this.ctrl = (val as unknown) as NgControl
  }
  @Output() outputEvent : EventEmitter<boolean> = new EventEmitter<boolean>(); 

  constructor() {}

  detectChanges($e:any) {
    let d:any = {key: this.fields.mapping.key, togglers: this.fields.mapping.related_toggler}
    this.outputEvent.emit(d)
  }
}
