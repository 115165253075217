import { NgModule } from '@angular/core';
import { LexiTableRoutingModule } from './lexi-table.routing.module';

@NgModule({
  declarations: [
  ],
  imports: [
    LexiTableRoutingModule
  ],
  exports: [
  ]
})
export class LexiTableModule { }
