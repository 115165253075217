import { Injectable, ViewContainerRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class TableMenuService {

  private vcr : ViewContainerRef | null = null;
  private _func:any = {};

  public setVCR(vcr: ViewContainerRef) {
    this.vcr = vcr;
  }
  public getVCR() {
    return this.vcr
  }
  constructor() { }

  append(key:string, func:any) {
    this._func[key] = func;
  }

  get(key:string, uuid: string, parrent_uuid: string) {
    return this._func[key](this.vcr, uuid, parrent_uuid)
  }

}
