import { Component, OnInit } from '@angular/core';
import { ApiService } from 'lexi-api';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'lib-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  public token:any;
  public data:any = {};
  public progress:any = 1;
  constructor(private api: ApiService, private cookieService: CookieService) { 
    this.token = this.cookieService.get('token') ? this.cookieService.get('token') : null;
  }

  ngOnInit(): void {
    this.initData();
  }

  async initData() {
    this.data = await this.api.post('/users/get-detail', {token: this.token})
    console.log(this.data)
  }

  progressTo(step:any){
    this.progress = step;
  }

  async submit(type:any) {
    const result = await this.api.post('/users/update-detail', {type: type, data: this.data})
    if(!result.status){
      alert(result.message)
      return;
    }
    return this.progress = 1;
  }
}
