<div class="flex-fill border">
    <div class="d-flex flex-row">
        <div class="col-md-2 d-none d-md-block min-height border-right sidebar">
            <div class="flex-fill pd-y-20">
                <div class="d-flex align-items-center justify-content-between pd-x-10 mg-b-10" *ngFor="let field of groups.fields">
                  <span class="tx-10 tx-uppercase tx-medium tx-color-03 tx-sans tx-spacing-1">{{field['title']}} : {{groups.data[field['key']]}}</span>
                </div>
                <nav id="allChannels" class="nav flex-column nav-chat mg-b-20">
                    <a [routerLink]="['/detail', existing_path, uuid, menu.type, menu.path, uuid]" [ngClass]="{'active': getCurrentRoutes() == ('/detail' + '/' + existing_path + '/' + uuid + '/' + menu.type + '/' + menu.path + '/' + uuid)}" class="nav-link"  *ngFor="let menu of groups.menus; let i = index">
                        {{menu.title}}
                    </a>
                </nav>
            </div>
        </div>
        <div class="col-md-10 col-12 py-3 min-height">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>