import { Component, OnInit, Input} from '@angular/core';
import { LegendPosition } from '@swimlane/ngx-charts';

@Component({
  selector: 'lib-lexi-chart',
  templateUrl: './lexi-chart.component.html',
  styleUrls: ['lexi-chart.component.css']
})
export class LexiChartComponent implements OnInit {
 
  @Input() data:any;
  @Input() type:any;


  /**
   * getData(data:any) {
   *  this.data = data;
   * }
   */


legendPosition = LegendPosition.Right; // .Right | .Below only
colorScheme = 'air'; 

lRightLabel = "Revenue";
lBelowLabel = "Years";

dash2PieLP = LegendPosition.Below;
  
// options
view: any = [930, 400];
Pview: any = [350, 400];
Pview2: any = [400, 400];
Lview: any = [900,320];
Bview: any = [650, 400];
APview: any = [850, 400];
showXAxis = true;
showYAxis = true;
gradient = false;
showLegend = true;
showXAxisLabel = true;
// xAxisLabel = 'Country';
showYAxisLabel = true;
// yAxisLabel = 'Population';
showLabels = true;
isDoughnut = true;
schemeType = 'linear';


  constructor() {
    this.data = {},
    // this.data.position = "LegendPosition.Right", 
    this.data.style = "horizon",
    //color include vivid, natural, cool, fire, air, solar, aqua, flame, ocean, forest, horizon, neons, picnic, night, nightLights
    // this.data.chart_type = "bar-h",
    this.data.chart_type = "line",
    this.data.chart_right_label = "Population",
    this.data.chart_below_label = "Country",

    this.data.multi_chart_data2 = [
      {
        "name": "X Company",
        "series": [
          {
            "name": "1990",
            "value": 62000000
          },
          {
            "name": "2000",
            "value": 73000000
          },
          {
            "name": "2005",
            "value": 77777000
          },
          {
            "name": "2010",
            "value": 79000000
          },
          {
            "name": "2015",
            "value": 87000000
          },
          {
            "name": "2020",
            "value": 9100000
          },
        ]
      },
    
    ],
  

    this.data.single_chart_data = [
    {
      "name": "Germany",
      "value": 8940000
    },
    {
      "name": "USA",
      "value": 5000000
    },
    {
      "name": "France",
      "value": 7200000
    },
    {
    "name": "UK",
    "value": 7500000
    }
  ],

  this.data.single_chart_data2 = [

    {
      "name": "Jan",
      "value": 62000000
    },
    {
      "name": "Feb",
      "value": 73000000
    },
    {
      "name": "Mar",
      "value": 77777000
    },
    {
      "name": "Apr",
      "value": 79000000
    },
    {
      "name": "May",
      "value": 87000000
    },
    {
      "name": "June",
      "value": 91000000
    },
    {
      "name": "July",
      "value": 91000000
    },
    {
      "name": "Aug",
      "value": 91000000
    },
    {
      "name": "Sep",
      "value": 91000000
    }
  ]

  this.data.multi_chart_data = [
    {
      "name": "Germany",
      "series": [
        {
          "name": "1990",
          "value": 62000000
        },
        {
          "name": "2010",
          "value": 73000000
        },
        {
          "name": "2011",
          "value": 89400000
        }
      ]
    },
  
    {
      "name": "USA",
      "series": [
        {
          "name": "1990",
          "value": 250000000
        },
        {
          "name": "2010",
          "value": 309000000
        },
        {
          "name": "2011",
          "value": 311000000
        }
      ]
    },
  
    {
      "name": "France",
      "series": [
        {
          "name": "1990",
          "value": 58000000
        },
        {
          "name": "2010",
          "value": 50000020
        },
        {
          "name": "2011",
          "value": 58000000
        }
      ]
    },
    {
      "name": "UK",
      "series": [
        {
          "name": "1990",
          "value": 57000000
        },
        {
          "name": "2010",
          "value": 62000000
        },
        {
          "name": "2011",
          "value": 60000000
        }
      ]
    }
  ]
  
    
   }

  ngOnInit(): void {
  }




  onSelect(event:any) {
    console.log(event);
  }

  onActivate(event:any) {
    console.log('Activate', JSON.parse(JSON.stringify(event)));
  }

  onDeactivate(event:any) {
    console.log('Deactivate', JSON.parse(JSON.stringify(event)));
  }
}
