import { Component, Input } from '@angular/core';
import { NgControl, AbstractControl } from '@angular/forms';

@Component({
  selector: 'lib-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent {

  public ctrl:any;
  public address: boolean = true;
  public coordinates: boolean = false;

  @Input() field: any = {};
  @Input() set control(val : AbstractControl) {
    this.ctrl = (val as unknown) as NgControl
  }
  
  constructor() { }

  show_address() {
    // if(this.address == true){
    //   this.address = false;
    //   this.coordinates = true;
    // }
    this.address = true;
    this.coordinates = false;

    

  }

  show_coordinates() {
  //   if(this.coordinates == true){
  //     this.coordinates = false;
  //     this.address = true;
  // }
  this.address = false;
  this.coordinates = true;

  }

}
