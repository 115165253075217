<div *ngIf="table_not_found">
    <h4 class="m-0">Table Structure Not Found. Contact Administrator for assistant</h4>
</div>

<div class="d-flex flex-row justify-content-between pb-2 align-items-center">
    <div class="d-flex flex-row">
        <div class="spinner mr-2" *ngIf="loading"></div>
        <h4 class="m-0 px-3">{{structure.title}}</h4>
    </div>
    <div class="d-flex flex-row">
        <ng-template #filtersDateView></ng-template>
        <ng-template #filtersView></ng-template>
        <ng-template #buttonsView></ng-template>
    </div>
</div>

<div #table [class]="table_option.wrapperClass"></div>
