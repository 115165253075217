<div class="d-flex flex-row justify-content-between pb-2 align-items-center border-bottom mb-2">
    <div class="d-flex flex-row">
        <div class="spinner mr-2" *ngIf="loading"></div>
        <h4 class="m-0">{{structure.title}}</h4>
    </div>
    <div class="d-flex flex-row">
        <ng-template #buttonsView></ng-template>
    </div>
</div>

<div class="row mt-3">
    <div [class]="form_option.wrapperClass">
        <form (submit)="submit($event)">
            <ng-template #wrapper></ng-template>
            <div class="d-flex flex-row justify-content-between">
                <button type="button" *ngIf="structure.redirect && !subview" (click)="redirect()" [disabled]="loading" class="btn btn-sm btn-outline-primary" >Cancel</button>    
                <button type="submit" [disabled]="loading" class="btn btn-sm btn-primary">Submit</button>
            </div>
        </form>        
    </div>
</div>

