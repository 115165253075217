<div [ngStyle]="{'min-height' : '3000px'}">

<div [ngSwitch]="data.chart_type">

  <div *ngSwitchCase="'line'">
    <ngx-charts-line-chart
    [view]="view"
    [scheme]="data.style"
    [results]="data.multi_chart_data"
    [gradient]="gradient"
    [xAxis]="showXAxis"
    [yAxis]="showYAxis"
    [legend]="showLegend"
    [legendPosition]="legendPosition"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [xAxisLabel]="data.chart_below_label"
    [yAxisLabel]="data.chart_right_label">
  </ngx-charts-line-chart>
  </div>

  <div *ngSwitchCase="'pie'">
    <ngx-charts-pie-chart
    [view]="view"
    [scheme]="data.style"
    [results]="data.single_chart_data"
    [gradient]="gradient"
    [legend]="showLegend"
    [legendPosition]="legendPosition"
    [labels]="showLabels"
    [doughnut]="isDoughnut"
    (select)="onSelect($event)"
    (activate)="onActivate($event)"
    (deactivate)="onDeactivate($event)"
    >
    </ngx-charts-pie-chart>
  </div>

  <div *ngSwitchCase="'bar-h'">
    <ngx-charts-bar-horizontal
      [view]="view"
      [scheme]="data.style"
      [results]="data.single_chart_data"
      [gradient]="gradient"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      [legend]="showLegend"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="showYAxisLabel"
      [xAxisLabel]="data.chart_below_label"
      [yAxisLabel]="data.chart_right_label"
      (select)="onSelect($event)"
      (activate)="onActivate($event)"
      (deactivate)="onDeactivate($event)">
    </ngx-charts-bar-horizontal>
  </div>

  <div *ngSwitchCase = "'bar-v'">
    <ngx-charts-bar-vertical
      [view]="view"
      [scheme]="data.style"
      [results]="data.single_chart_data"
      [gradient]="gradient"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      [legend]="showLegend"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="showYAxisLabel"
      [xAxisLabel]="data.chart_below_label"
      [yAxisLabel]="data.chart_right_label">
    </ngx-charts-bar-vertical>
  </div>

  <div *ngSwitchCase = "'bar-gh'">
    <ngx-charts-bar-horizontal-2d
      [view]="view"
      [scheme]="data.style"
      [results]="data.multi_chart_data"
      [gradient]="gradient"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      [legend]="showLegend"
      [legendPosition]="legendPosition"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="showYAxisLabel"
      [xAxisLabel]="data.chart_below_label"
      [yAxisLabel]="data.chart_right_label"
      (select)="onSelect($event)"
      (activate)="onActivate($event)"
      (deactivate)="onDeactivate($event)"
      >
    </ngx-charts-bar-horizontal-2d>
  </div>

  <div *ngSwitchCase = "'bar-gv'">
    <ngx-charts-bar-vertical-2d
      [view]="view"
      [scheme]="data.style"
      [results]="data.multi_chart_data"
      [gradient]="gradient"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      [legend]="showLegend"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="showYAxisLabel"
      [xAxisLabel]="data.chart_below_label"
      [yAxisLabel]="data.chart_right_label"
      (select)="onSelect($event)"
      (activate)="onActivate($event)"
      (deactivate)="onDeactivate($event)">
    </ngx-charts-bar-vertical-2d>
  </div>

</div>

</div>


<div class="min-height:5000px">



  <div class="row">
    <div class="col-4">
      <div class="row">
        <div class="col-6">
          <div class="card" [ngStyle]="{'min-height': '150px'}">
            <div class="card-body">
              <div class="row">
              <div class="col">
              <h5 class="card-title">Sales</h5>
            </div>
              <div class="col-auto">
                <div class="stat text-primary">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-truck align-middle"><rect x="1" y="3" width="15" height="13"></rect><polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon><circle cx="5.5" cy="18.5" r="2.5"></circle><circle cx="18.5" cy="18.5" r="2.5"></circle></svg>
                </div>
              </div>
            </div>
              <h1 class="dash2 mt-3 mb-3">2.382</h1>
              <div class="mb-0">
                <span class="badge badge-primary-light mr-2"> <i class="mdi mdi-arrow-bottom-right py-1 me-1"></i> -3.65% </span>
                <span class="text-muted">Since last week</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="card" [ngStyle]="{'min-height': '150px'}">
            <div class="card-body">
              <div class="row">
              <div class="col">
              <h5 class="card-title">Earnings</h5>
            </div>
              <div class="col-auto">
                <div class="stat text-primary">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign align-middle"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                </div>
              </div>
            </div>
              <h1 class="dash2 mt-3 mb-3">$21.300</h1>
              <div class="mb-0">
                <span class="c2 badge badge-primary-light mr-2"> <i class="mdi mdi-arrow-bottom-right py-1 me-1"></i> 6.65% </span>
                <span class="text-muted">Since last week</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-6">
          <div class="card" [ngStyle]="{'min-height': '150px'}">
            <div class="card-body">
              <div class="row">
              <div class="col">
              <h5 class="card-title">Visitors</h5>
            </div>
              <div class="col-auto">
                <div class="stat text-primary">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users align-middle"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                </div>
              </div>
            </div>
              <h1 class="dash2 mt-3 mb-3">14.212</h1>
              <div class="mb-0">
                <span class="c3 badge badge-primary-light mr-2"> <i class="mdi mdi-arrow-bottom-right py-1 me-1"></i> 5.25%</span>
                <span class="text-muted">Since last week</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="card" [ngStyle]="{'min-height': '150px'}">
            <div class="card-body">
              <div class="row">
              <div class="col">
              <h5 class="card-title">Orders</h5>
            </div>
              <div class="col-auto">
                <div class="stat text-primary">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-cart align-middle"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg>
                </div>
              </div>
            </div>
              <h1 class="dash2 mt-3 mb-3">64</h1>
              <div class="mb-0">
                <span class="c4 badge badge-primary-light mr-2"> <i class="mdi mdi-arrow-bottom-right py-1 me-1"></i> -2.25% </span>
                <span class="text-muted">Since last week</span>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>

    <div class="col-8 border">
      <ngx-charts-line-chart
      [view]="Lview"
      [scheme]="data.style"
      [results]="data.multi_chart_data2"
      [gradient]="gradient"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      [legend]="showLegend"
      [legendPosition]="legendPosition"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="showYAxisLabel"
      [xAxisLabel]="lBelowLabel"
      [yAxisLabel]="lRightLabel">
    </ngx-charts-line-chart></div>

  </div>

  <div class="row mt-3">

    <div class="col ml-3">
      <div class="row d-flex justify-content-start">
        <div class="col-11 border">
          <div class="card-header fw-bold">
            <h3>Latest Orders</h3>
          </div>
          <table class="table rounded bg-white">
            <thead>

            </thead>
            <tbody class="">
              <tr>
                <td scope="row">2323</td>
                <td>Devon Lane</td>
                <td>devon@example.com</td>
                <td>$778.35</td>
                <td>Delivered</td>
                <td>07.05.2020</td>
              </tr>
              <tr>
                <td scope="row">2456</td>
                <td>Darrell Steward</td>
                <td>darrell@example.com</td>
                <td>$219.78</td>
                <td>Delivered</td>
                <td>03.07.2020</td>
              </tr>
              <tr>
                <td scope="row">6289</td>
                <td>Darlene Robertson</td>
                <td>darlene@example.com</td>
                <td>$928.41</td>
                <td>Cancelled</td>
                <td>23.03.2020</td>
              </tr>
              <tr> 
                <td scope="row">3869</td>
                <td>Courtney Henry</td>
                <td>courtney@example.com</td>
                <td>$90.51</td>
                <td>Pending</td>
                <td>04.07.2020</td>
              </tr>
              <tr>
                <td scope="row">1247</td>
                <td>Eleanor Pena</td>
                <td>eleanor@example.com</td>
                <td>$275.43</td>
                <td>Delivered</td>
                <td>10.03.2020</td>
              </tr>
              <tr>
                <td scope="row">3961</td>
                <td>Ralph Edwards</td>
                <td>ralph@example.com</td>
                <td>$630.44</td>
                <td>Delivered</td>
                <td>23.03.2020</td>
              </tr>

              <tr>
                <td scope="row">3961</td>
                <td>Ralph Edwards</td>
                <td>ralph@example.com</td>
                <td>$630.44</td>
                <td>Delivered</td>
                <td>23.03.2020</td>
              </tr>

              <tr>
                <td scope="row">3961</td>
                <td>Ralph Edwards</td>
                <td>ralph@example.com</td>
                <td>$630.44</td>
                <td>Delivered</td>
                <td>23.03.2020</td>
              </tr>

              <tr>
                <td scope="row">3961</td>
                <td>Ralph Edwards</td>
                <td>ralph@example.com</td>
                <td>$630.44</td>
                <td>Delivered</td>
                <td>23.03.2020</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="col-3 border">
      <ngx-charts-pie-chart
      [view]="Pview"
      [scheme]="data.style"
      [results]="data.single_chart_data"
      [gradient]="gradient"
      [legendPosition]="legendPosition"
      [labels]="showLabels"
      [doughnut]="isDoughnut"
      (select)="onSelect($event)"
      (activate)="onActivate($event)"
      (deactivate)="onDeactivate($event)">
      </ngx-charts-pie-chart>
    </div>
    </div>

</div>




<div class="mt-5">


<div class="row">
  <div class="col-3">
    <div class="card" [ngStyle]="{'min-height': '110px'}">
      <div class="card-body row">
        <div class="col-2 me-5">
          <svg xmlns="http://www.w3.org/2000/svg" width="43" height="52" fill="currentColor" class="bi bi-coin" viewBox="0 0 16 16">
  <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z"/>
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"/>
</svg></div> 
          <div class="col">
          <p class="text-black-50">Total Sales</p>
          <h5 class="">$ 19,626,058,20</h5>
          </div>
      </div>
    </div>
  </div>
  
  <div class="col-3">
    <div class="card" [ngStyle]="{'min-height': '110px'}">
      <div class="card-body row">
        <div class="me-5 col-2"> 
          <svg xmlns="http://www.w3.org/2000/svg" width="41" height="52" fill="currentColor" class="bi bi-cart" viewBox="0 0 16 16">
  <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
</svg></div>
          <div class="col">
          <p class="card-title text-black-50">Total Orders</p>
          <h5 class="card-text">3290</h5>
          </div>
      </div>
    </div>
  </div>

  <div class="col-3">
    <div class="card" [ngStyle]="{'min-height': '110px'}">
      <div class="card-body row">
        <div class="col-2 me-3">
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="55" fill="currentColor" class="bi bi-box-seam" viewBox="0 0 16 16">
  <path d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5l2.404.961L10.404 2l-2.218-.887zm3.564 1.426L5.596 5 8 5.961 14.154 3.5l-2.404-.961zm3.25 1.7-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z"/>
</svg>
          </div>
          <div class="col">
          <p class="card-title text-black-50">Total Products</p>
          <h5 class="card-text">322</h5>
          </div>
        </div>
      </div>
    </div>

    <div class="col-3">
      <div class="card" [ngStyle]="{'min-height': '110px'}">
        <div class="card-body row">
          <div class="me-5 col-2"> 
            <svg xmlns="http://www.w3.org/2000/svg" width="41" height="52" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
              <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"/>
            </svg></div>
            <div class="col">
            <p class="card-title text-black-50">Total New Users</p>
            <h5 class="card-text">400</h5>
            </div>
        </div>
      </div>
    </div>

  </div>

  <div class="row mt-3">
    <div class="col bg-white border" style="margin-left:1%">
      <ngx-charts-bar-vertical
      [view]="view"
      [scheme]="data.style"
      [results]="data.single_chart_data2"
      [gradient]="gradient"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="showYAxisLabel"
      [xAxisLabel]="lBelowLabel"
      [yAxisLabel]="lRightLabel"
      (select)="onSelect($event)"
      (activate)="onActivate($event)"
      (deactivate)="onDeactivate($event)" class="border">
    </ngx-charts-bar-vertical>
    </div>
    
    <div class="col d-flex justify-content-end">
        <ngx-charts-pie-chart
        [view]="Pview2"
        [scheme]="data.style"
        [results]="data.single_chart_data"
        [gradient]="gradient"
        [legendPosition]="legendPosition"
        [labels]="showLabels"
        [doughnut]="isDoughnut"
        (select)="onSelect($event)"
        (activate)="onActivate($event)"
        (deactivate)="onDeactivate($event)"
        class="bg-white border">
        </ngx-charts-pie-chart>
    </div>
  </div>

  <div class="mt-4 row mx-0">
    <div class="col-12 border">
      <div class="card-header fw-bold">
        <h3>Latest Orders</h3>
      </div>
      <table class="table rounded bg-white">
        <thead>

        </thead>
        <tbody class="">
          <tr>
            <td scope="row">2323</td>
            <td>Devon Lane</td>
            <td>devon@example.com</td>
            <td>$778.35</td>
            <td>Delivered</td>
            <td>07.05.2020</td>
          </tr>
          <tr>
            <td scope="row">2456</td>
            <td>Darrell Steward</td>
            <td>darrell@example.com</td>
            <td>$219.78</td>
            <td>Delivered</td>
            <td>03.07.2020</td>
          </tr>
          <tr>
            <td scope="row">6289</td>
            <td>Darlene Robertson</td>
            <td>darlene@example.com</td>
            <td>$928.41</td>
            <td>Cancelled</td>
            <td>23.03.2020</td>
          </tr>
          <tr> 
            <td scope="row">3869</td>
            <td>Courtney Henry</td>
            <td>courtney@example.com</td>
            <td>$90.51</td>
            <td>Pending</td>
            <td>04.07.2020</td>
          </tr>
          <tr>
            <td scope="row">1247</td>
            <td>Eleanor Pena</td>
            <td>eleanor@example.com</td>
            <td>$275.43</td>
            <td>Delivered</td>
            <td>10.03.2020</td>
          </tr>
          <tr>
            <td scope="row">3961</td>
            <td>Ralph Edwards</td>
            <td>ralph@example.com</td>
            <td>$630.44</td>
            <td>Delivered</td>
            <td>23.03.2020</td>
          </tr>

          <tr>
            <td scope="row">3961</td>
            <td>Ralph Edwards</td>
            <td>ralph@example.com</td>
            <td>$630.44</td>
            <td>Delivered</td>
            <td>23.03.2020</td>
          </tr>

          <tr>
            <td scope="row">3961</td>
            <td>Ralph Edwards</td>
            <td>ralph@example.com</td>
            <td>$630.44</td>
            <td>Delivered</td>
            <td>23.03.2020</td>
          </tr>

          <tr>
            <td scope="row">3961</td>
            <td>Ralph Edwards</td>
            <td>ralph@example.com</td>
            <td>$630.44</td>
            <td>Delivered</td>
            <td>23.03.2020</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div> 
